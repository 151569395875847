.workoutContainer {
    width: 100%;
    min-width: 280px;
    height: 200px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    background-color: white;

    .imageWrapper {
        position: relative;

        .image {
            width: 100%;
            height: 280px;
            object-fit: cover;

            // border-radius: 15%;
            border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -o-border-radius: 30px;
        }

        .gradientOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(to bottom, rgba(38, 38, 46, 0) 40%, rgba(38, 38, 46, 1) 100%);
        }
    }

    .premiumImage {
        @extend .blur;

        @extend .opacity;
    }

    .title {
        color: white;
        font-weight: bold;
        position: absolute;
        bottom: 16px;
        left: 20px;
    }
}
