.templateContainer {
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    background-color: $balticSea;


    .imageWrapper {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;

        .image {
            width: 100%;
            height: 280px;
            object-fit: cover;

            // border-radius: 15%;
            border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -o-border-radius: 30px;
        }

        .gradientOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(to bottom, rgba(38, 38, 46, 0) 40%, rgba(38, 38, 46, 1) 100%);
        }
    }

    .textContainer {
        position: absolute;
        z-index: 2;
        bottom: 16px;
        width: 90%;
        padding: 0 24px;


        .title {
            color: white;
            font-weight: bold;
        }

        .description {
            color: $grey;
            font-weight: lighter;
        }
    }
}