.OtherProfile {
    width: 100%;
    height: fit-content;
    padding-bottom: 20px;

    .profile_header {
        background-color: #1A1A1A;
        border-radius: 30px;
        padding: 20px;
        min-width: 280px;

        .profile_image {
            width: clamp(100px, 15vw + 50px, 150px);
            height: clamp(100px, 15vw + 50px, 150px);
            border-radius: 150PX;
            object-fit: cover;
        }

        .profile_name {
            color: white;
            font-size: 28px;
            font-weight: bold;

            @media (max-width:991px) {
                text-align: center;
            }
        }

        .profile_description {
            color: #929292;
        }

        .profile_sport_type {
            color: #929292;
            font-weight: bold;
        }

        .userTagsContainer {

            span.tag {
                display: inline-flex;
                margin: 0 5px 0 20px;
                position: relative;

                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    top: 50%;
                    left: -8px;
                    transform: translate(-50%, 0%);
                    -moz-border-radius: 7.5px;
                    -webkit-border-radius: 7.5px;
                    border-radius: 7.5px;
                    background-color: white;
                }
            }

            @media (max-width:991px) {
                display: inline-block;
                text-align: center;
            }
        }

        .count_follow_style {
            border-left: 2px solid #353742;

            @media only screen and (max-width: 991px) {
                border-left: none;

            }

            .gap_row {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 90px;

                .following_followers_count {
                    font-size: 40px;
                    font-weight: bold;
                    cursor: pointer;
                }

                .following_followers_text {
                    color: #929292;
                    font-weight: lighter;
                    font-size: 20px;
                }
            }

            .purchase-btn {
                color: white !important;
                border: 1px solid white;
                border-radius: 20px;
                background-color: transparent !important;
            }

            .following-btn {
                color: $carrotOrange;
                border: 1px solid $carrotOrange;
                border-radius: 20px;
                background-color: transparent !important;
            }


        }


    }

    .badge {
        width: 100px;

        p {
            text-wrap: wrap;
            font-size: small;
        }
    }

    .post {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(3, 1fr);

        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media only screen and (min-width: 1200px) {
            grid-template-columns: repeat(5, 1fr);
        }

        img {
            width: 100%;
            border-radius: 25px;
            height: auto;
            display: block;
            object-fit: cover;
        }
    }
}