.checkbox-wrapper-22 {
    .switch {
        display: inline-block;
        height: 28px; // slider outer
        position: relative;
        width: 56px;
    }

    .switch input {
        display: none;
    }

    .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;

        &:before {
            background-color: #fff;
            bottom: 3px;
            content: "";
            height: 22px; // slider inner
            left: 4px;
            position: absolute;
            transition: .4s;
            width: 22px; // slider inner
       
        }
    }

    input:checked + .slider {
        background-color: $carrotOrange;

        &:before {
            transform: translateX(26px);
        }
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}
