// MARK: COLORS
$mainBgColor: #1A1A1A !important;
$rangoonGreen: #1A1A1A !important;
$primaryColor: #F68B28 !important;
$carrotOrange: #F68B28 !important;
$primaryColorWithAlpha: #F68B281a !important;
$white: #FFFFFF !important;
$secondBgColor: #26262E !important;
$balticSea: #26262E !important;
$modalInnerBoxColor: #18181D !important;
$mirage: #18181D !important;
$modalBgColor: #1F2025 !important;
$darkJungleGreen: #1F2025 !important;
$artyClickRed: #FF0000 !important;
$orangeyRed: #F64D28 !important;
$grey: #929292 !important;
$silverChalice: #A9A9A9 !important;
$gunmetal: #31323C !important;
$soapstone: #FCFCFC !important;
$dune: #313131 !important;
$lemonGrass: #9A9A9A !important;
$amaranth: #EB2560 !important;
$vibrantGreen: #19DF2D !important;
$darkSkyBlue: #3086F3 !important;
$coralRed: #FC4545 !important;
$superNova: #FFCC00 !important;
$softGreen: #74C772 !important;
$blackCow: #484848 !important;
$darkGrey: #373737 !important;
$frenchGrey: #BDBDBD !important;
$carbonGrey: #5C5C5C !important;
$granite: #808080 !important;

//old

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;

// MARK: GLOBAL CLASSES
.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.bg-darkJungleGreen {
    background-color: $darkJungleGreen;
}

.bg-carrotOrange {
    background-color: $carrotOrange;
}

.text-granite {
    color: $granite;
}

.color-success {
    color: $vibrantGreen
}

.color-error {
    color: $amaranth
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    color: white !important;
    background-color: $primaryColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}

.box-shadow {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13);
}

.blur {
    filter: blur(10px);
}

.blur5 {
    filter: blur(5px);
}

.opacity {
    opacity: .5;
}

// MARK: EXPORTED VARIABLE
// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
}