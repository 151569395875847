.imageContainer {
    width: 100%;
    height: fit-content;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    background-color: white;

    .checkBox {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .image {
        width: 100%;
        height: 280px;
        object-fit: cover;

        // border-radius: 15%;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .premiumImage {
        @extend .blur;

        @extend .opacity;
    }

    .templateBox {
        min-width: 400px;
        min-height: 280px;
        background-color: $blackCow;
    }
}
