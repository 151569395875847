@import "../../../global";

.ProtectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100dvh;
    width: 100%;

    .background {
        display: none;

        @media (max-width: 991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100dvh;
            top: 0;
            left: 0;
            background-color: $darkJungleGreen;
            opacity: 0.7;
        }
    }

    .menu {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        overflow: overlay;

        // padding: 0 15px;
        background-color: $rangoonGreen;
        transition: 0.3s;
        color: white;

        // transition: width 2s cubic-bezier(0.075, 0.82, 0.165, 1);

        @media (max-width: 991px) {
            position: absolute;
            left: -300px;
            z-index: 10;
        }

        &.collapsed {
            width: 300px;
            left: 0;
            transition: width 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &.notCollapsed {
            width: 90px;
            align-items: center;
            transition: width 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        .drawer {
            display: none;
            text-align: center;
            width: 100%;
            height: 20px;
            margin: 25px auto 50px;

            span {
                cursor: pointer;
                background-color: transparent;
                font-size: 25px;
            }

            &.collapsed {
                text-align: right;
            }

            @media (max-width: 991px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            margin: 0 auto;
            text-align: center;

            // padding: 25px 12%;
            min-height: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.notCollapsed {
                padding: 0;
            }

            img {
                width: 100%;
                cursor: pointer;
                aspect-ratio: 1;

                &.imageCollapsed {
                    height: 60px;
                    transition: height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                &.imageNotCollapsed {
                    max-width: 60px;
                    max-height: 60px;
                    transition: max-width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                    transition: max-height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
            }
        }

        .profileButton {
            padding: 8px 25px;
            white-space: nowrap;
            color: white;
            border-radius: 5px;
            background-color: $carrotOrange;
            font-size: 16px;
            font-weight: bold;
            border: none;

            &.notCollapsed {
                padding: 0;
                padding-top: 4px !important;
                padding-bottom: 4px !important;
            }

            &:focus-visible,
            &:focus-within {
                outline: none;
            }
        }

        // @media (max-width : 991px) {
        //     display: none;
        // }

        .item {
            width: 100%;
            height: 60px;
            margin: 2px 0;
            padding: 18px 24px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            color: white;

            // font-weight: 600;
            font-size: 14px;
            transition: background-color 0.3s ease, color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: background-color 0.3s ease, color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

            &:hover {
                background-color: $primaryColorWithAlpha;
            }

            &.active {
                background-color: $primaryColorWithAlpha;

                // border-radius: 15px;
                color: $carrotOrange;
                font-weight: 500;
                border-right: 10px solid $carrotOrange;
            }

            p {
                margin: 0 10px;
                white-space: nowrap;
            }
        }

        .subMenu {
            // height: 100dvh;
            display: flex;
            flex-direction: column;
            overflow: overlay;
            padding: 0 60px;

            // background-color: $balticSea;
            transition: 0.3s;
            color: white;

            // @media (max-width: 991px) {
            //     position: absolute;
            //     left: -300px;
            //     z-index: 10;
            // }

            &.collapsed {
                // width: 300px;
                // left: 0;

            }

            &.notCollapsed {
                // width: 90px;
                // align-items: center;
                padding: 0 10px;
            }

            .subItem {
                width: 100%;
                height: 30px;
                margin: 2px 0;

                // padding: 18px 24px;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                color: white;

                // font-weight: 600;
                font-size: 14px;
                transition: background-color 0.3s ease, font-size 0.3s ease, color 0.3s ease;

                &.collapsed {
                    justify-content: center;
                }

                &:hover {
                    background-color: $primaryColorWithAlpha;
                }

                &.active {
                    // background-color: $primaryColorWithAlpha;

                    // border-radius: 15px;
                    color: $carrotOrange;
                    font-weight: bold;

                    // border-right: 10px solid $carrotOrange;
                    border-bottom: 1px solid $carrotOrange;
                    font-size: large;
                }
            }
        }
    }

    .routeContent {
        position: relative;
        width: 100%;
        background-color: $darkJungleGreen;
        display: flex;
        flex-direction: column;
        height: 100dvh;
        overflow: hidden;

        .content {
            width: 100%;
            height: -webkit-fill-available;
            display: flex;
            overflow: auto;
            padding: 20px 20px 0px 20px;
        }
    }
}