.logTrainingModalContainer {
    padding: 20px;
    width: 100%;
    max-width: 500px;

    // height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .rounded-button {
        color: white;
        border-radius: 200px;
        min-width: 140px;
        min-height: 140px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-bottom: 10px;
    }

    .check-in {
        background-color: #F68B28;
        border: 2px solid #FFAF65;
        box-shadow: 0px 0px 32px rgba(246, 139, 40, 0.32);

        &:hover {
            background-color: #F57B1F;
        }
    }

    .check-out {
        background-color: #F62828 !important;
        border: 2px solid #DD7272 !important;
        box-shadow: 0px 0px 32px rgba(246, 40, 40, 0.32) !important;

        &:hover {
            background-color: #E62626 !important;
        }
    }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}
