/* MARK: BUTTONS ANIMATION */
.puff-in-hor {
    -webkit-animation: puff-in-hor 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    animation: puff-in-hor 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@-webkit-keyframes puff-in-hor {
    0% {
        -webkit-transform: scaleX(2);
        transform: scaleX(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-hor {
    0% {
        -webkit-transform: scaleX(2);
        transform: scaleX(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

/* MARK: LOGO ANIMATION */
.bounce-in-right {
    -webkit-animation: bounce-in-right 1.1s both;
    animation: bounce-in-right 1.1s both;
}

@-webkit-keyframes bounce-in-right {
    0% {
        -webkit-transform: translateX(600px);
        transform: translateX(600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(68px);
        transform: translateX(68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(32px);
        transform: translateX(32px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-right {
    0% {
        -webkit-transform: translateX(600px);
        transform: translateX(600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateX(68px);
        transform: translateX(68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateX(32px);
        transform: translateX(32px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

/* MARK: MAIN PUBLIC IMAGE  */
.scale-in-hor-center {
    -webkit-animation: scale-in-hor-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-hor-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

@keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

.scale-out-horizontal {
    -webkit-animation: scale-out-horizontal 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: scale-out-horizontal 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-horizontal {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
}

@keyframes scale-out-horizontal {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
}

/* MARK: MAIN PUBLIC IMAGE PEACOCK */
.slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-bottom-slow {
    -webkit-animation: slide-in-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-bottom-fast {
    -webkit-animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* MARK: TEXT FOR LOGIN AND REGISTER */
.focus-in-expand {
    -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.focus-in-expand-fast {
    -webkit-animation: focus-in-expand 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: focus-in-expand 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.focus-in-expand-slow {
    -webkit-animation: focus-in-expand 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: focus-in-expand 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes focus-in-expand {
    0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes focus-in-expand {
    0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

/* MARK: BUTTONS ANIMATIONS AND FORMS */
.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center-slow {
    -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/* MARK: LOGIN OR SIGN UP TEXT */

.tracking-in-expand-fwd-bottom-fast {
    -webkit-animation: tracking-in-expand-fwd-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand-fwd-bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.tracking-in-expand-fwd-bottom {
    -webkit-animation: tracking-in-expand-fwd-bottom 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand-fwd-bottom 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.tracking-in-expand-fwd-bottom-slow {
    -webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-expand-fwd-bottom {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px) translateY(500px);
        transform: translateZ(-700px) translateY(500px);
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px) translateY(500px);
        transform: translateZ(-700px) translateY(500px);
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

/* MARK: FOR BACK BUTTON */
.slide-in-right-slow {
    -webkit-animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right-fast {
    -webkit-animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/* MARK: SUB MENU ANIMATION */
.slide-in-left-slow {
    -webkit-animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-left-fast {
    -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/* MARK: Navbar Animation */
.slide-in-top-slow {
    -webkit-animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top-fast {
    -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* MARK: Button Animation  */
.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.scale-up-center-slow {
    -webkit-animation: scale-up-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* MAKR: BLINK ANIMATION */

.blink {
    -webkit-animation: blink 4s ease 0s infinite normal forwards;
    animation: blink 4s ease 0s infinite normal forwards;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* MARK: SHIMMER EFFECT */
.shimmer {
    font-weight: 300;
    font-size: 3em;
    margin: 0 auto;
    padding: 0 140px 0 0;
    display: inline;
    margin-bottom: 0;
}

.shimmer {
    /* width: 100%; */
    text-align: center;
    color: rgba(255, 255, 255, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #222;
}

@-moz-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}

@keyframes shimmer {
    0% {
        background-position: top left;
    }

    100% {
        background-position: top right;
    }
}


.image-placeholder-animate {
    animation-name: overlay;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
}

@keyframes overlay {
    0% {
        left: -5%;
    }

    100% {
        left: 100%;
    }
}

.tag-animate {
    animation-name: fade-in-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}