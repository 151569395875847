.Privacy {
    color: white;
    width: 100%;

    .termsBtn {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-content: center;

        p {
            margin: 0px;
            text-decoration-line: underline;
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.Terms-Condition {
    color: white;
    width: 100%;
    overflow-y: scroll;
}
