.Shared-Post {
    color: white;
    width: 100%;
    overflow-y: auto;


    .Community-Post-Following {
        position: relative;
        width: 100%;
        padding: 16px !important;
        border-radius: 18px;
        background-color: $balticSea;
        margin: 12px 0px;
        overflow-y: scroll;

        .popover {
            display: none;
            position: absolute;
            right: 10px;
            top: 50px;
            background: $rangoonGreen;
            border: 1px solid $darkJungleGreen;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
            z-index: 1000;

            p {
                padding: 10px;

                &:hover {
                    background-color: $darkJungleGreen;
                    border-radius: 8px;
                }
            }

            .show {
                display: flex;
                width: -moz-fit-content;
                width: fit-content;
                height: -moz-fit-content;
                height: fit-content;
            }
        }



        .commentsArea {
            border-radius: 18px;
            background-color: $darkJungleGreen;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-in-out;
        }

        .show {
            overflow: auto;
            padding: 20px;
            max-height: -moz-fit-content;
            max-height: fit-content;
        }


    }

    .Comment-Container {
        max-height: 500px;
        overflow: auto;
    }
}