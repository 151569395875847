@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bolder;
    src: url("./fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: bolder;
    src: url("./fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src: url("./fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 800;
    src: url("./fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

// @font-face {
//     font-family: 'Gilroy';
//     font-style: normal;
//     font-weight: 200;
//     src: url("./fonts/Gilroy/Gilroy-ExtraLight.ttf") format("truetype");
// }

// @font-face {
//     font-family: 'Gilroy';
//     font-style: italic;
//     font-weight: 200;
//     src: url("./fonts/Gilroy/Gilroy-ExtraLightItalic.ttf") format("truetype");
// }

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 300;
    src: url("./fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 500;
    src: url("./fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: 'Gilroy' !important;
}

/* p{
    color: white;
} */
