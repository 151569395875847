.chip {
    display: inline-block;
    padding: 0 10px;

    // height: 50px;
    font-size: 16px;
    line-height: 35px;
    border-radius: 10px;
    background-color: $carrotOrange;
    margin-right: 8px;
    margin-top: 8px;

    .closeChip {
        padding-left: 10px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            color: #000;
        }
    }
}
