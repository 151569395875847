.Setting {
    color: white;
    width: 100%;

    .mainContainer {
        width: 100%;

        // height: fit-content; //
        border-radius: 18px;
        background-color: $balticSea;
        padding: 18px;

        .profileImageDiv {
            // width: fit-content;
            // height: fit-content;
            position: relative;
            margin-bottom: 10px;

            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 100px;
            }

            .editBtn {
                background-color: white;
                border-radius: 8px;
                padding: 6px 6px 9px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 0px;
                right: 0px;
            }
        }

        .profileImageDiv {
            display: flex;
            position: relative;

            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 100px;
            }

            .editBtn {
                background-color: white;
                border-radius: 8px;
                padding: 6px 6px 9px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 0px;
                right: 0px;
            }

            .fileChooseInput {
                position: absolute;
                top: 0;
                left: 0;
            }

            .label {
                user-select: none;
                cursor: pointer;
                border-radius: 100%;
            }

            .userImage {
                width: 110px;
                height: 110px;
                border-radius: 100%;

            }

            .editBtn {
                background-color: white;
                border-radius: 8px;
                padding: 6px 6px 9px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 0px;
                right: 0px;
            }

        }

        .buttonDiv {
            min-width: 150px;
            width: fit-content;
        }

        input {
            background-color: $darkJungleGreen;
            border-radius: 8px !important;
        }

        .accountsDetails {
            .edit {
                color: $carrotOrange;
                font-weight: bold;
                border-bottom: 1px solid;
            }
        }

        .notification {
            background-color: $darkJungleGreen;
            border-radius: 8px !important;
            padding: 10px;
            color: #8F8F8F !important;
        }
    }

    // MARK: SELECT
    .select {
        background-color: $darkJungleGreen;
        border: 0px solid #48556F !important;
        border-radius: 8px !important;
        color: #8F8F8F !important;
        font-size: 16px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        line-height: 1.4 !important;
        padding: 15px;
        margin: 6px 0px 2px 0px;
        width: 100%;
        height: 50px;

        // arrow working
        background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
        background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0;
        background-size: 6px 10px, 6px 10px;
        background-repeat: no-repeat;

        // hide default arrow
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    /* .select::placeholder {
        color: #8F8F8F !important;
    } */

    .select:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    }

    .select:required:invalid {
        color: #8F8F8F !important;
    }

    option {
        color: white;
    }

    //  hide arrow of select field
    // .multi-select {
    //     width: 100%;
    //     height: 58px;
    //     margin: 6px 0px 2px 0px;
    //     display: flex;
    //     align-items: center;
    //     background-color: $darkJungleGreen;
    //     -webkit-appearance: none;
    //     -moz-appearance: none;

    //     border: 0px solid #48556F !important;
    //     border-radius: 8px !important;
    //     color: #8F8F8F !important;
    //     font-size: 16px !important;
    //     /* text-indent: 1px; */
    //     /* text-overflow: ''; */
    // }
}