.Feedback {
    color: white;
    width: 100%;

    .feedback-row{
        width: 100%;
        background-color: $balticSea;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;


        .first-row{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            h2{
                font-size: 24px !important;
            }
        }

        .textarea{
            background-color: $darkJungleGreen;
            margin: 20px 0px;
        }


        .right-side{
            width: 300px;
            margin-left: auto;
            padding: 10px 0px 10px 0px;
        }

    }
}
