.secondMenu {
    ul {
        width: fit-content;
        list-style-type: none;
        margin: 0;
        overflow: hidden;
        background-color: $balticSea;
        padding: 6px !important;
        border-radius: 18px;
        margin-bottom: 0px !important;
    }

    li {
        float: left;

        // margin: 0px 6px;
        // margin: 0rem 0.375rem;
        margin: 0 calc(0.125rem + (0.375rem - 0.125rem) * ((100vw - 320px) / (1280 - 320)));
    }

    li a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        border-radius: 18px;

        // margin: 0px 8px;
   
    }

    li a:hover:not(.active) {
        background-color: $darkJungleGreen;
    }

    .active {
        background-color: $carrotOrange;
    }
}

@media only screen and (max-width: 768px) {}
