.addMemberInGroupModalContainer {
    // padding: 20px;
    padding: 20px 20px 20px 20px;
    width: 100%;
    max-width: 500px;
    height: 100%;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: hidden;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .description {
        color: $frenchGrey;
    }

    .title-container {
        height: 30px;
    }

    .users-container {
        width: 100%;
        height: calc(100% - 40px - 72px - 30px);
        overflow: hidden;

        .chat-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
        }
    }

    .counter {
        padding: 8px 16px;
        background-color: $carrotOrange;
        border-radius: 20px;
        margin: 0px;
    }

    .fixed-button {
        height: 52px;
        width: 100%;
        background-color: $darkJungleGreen;

    }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}