.Profile {
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.userContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 4px solid #D43737
}

.uploadBtn {
    background-color: #202127;
    border-radius: 12px;
    padding: 8px;
    color: #F1C256;
    font-size: 14px;
    margin-top: 8px;
    cursor: pointer;
}

.nameContainer {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.userName {
    color: white;
    font-weight: bold;
    font-size: 30px;
    padding: 5px;
}

.showCoins {
    width: 100%;
    height: fit-content;
    background-color: #202127;
    border-radius: 16px;
    margin-left: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dc {
    background-color: #F1C256;
    border-radius: 16px;
    padding: 16px;
    font-size: 20px;
    width: fit-content;
}

.plusIcons {
    background-color: #161616;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8F8F8F;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 5px 5px;
}

/* .avatarContainer {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 20px;
  }
  
  .avatarContainer .row {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

.avatarCol {
    text-align: center;
    padding: 10px;
} */

.avatarImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.select {
    background-color: #202127 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: #8F8F8F !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 35px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    height: 50px;
    font-family: 'Gilroy';
}

/* .select::placeholder {
    color: #8F8F8F !important;
} */

.select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.select:required:invalid {
    color: #8F8F8F !important;
}

option {
    color: white;
}

/* hide arrow of select field */
.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    /* text-indent: 1px; */
    /* text-overflow: ''; */
}

.profile_header {
    background-color: #1A1A1A;
    border-radius: 30px;
    padding-inline: 20px;
    padding-block: 20px;
}

.profile_image {
    width: 150px;
    border-radius: 100%;
    aspect-ratio: 4 / 4;
}

.profile_name {
    font-size: 30px;
    font-weight: bold;
}

.profile_description {
    color: #929292;
}

.profile_sport_type {
    color: #929292;
    font-weight: bold;
}



.userTagsContainer span.tag {
    display: inline-flex;
    margin: 0 5px 0 20px;
    position: relative;
}

.userTagsContainer span.tag::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: 50%;
    left: -8px;
    transform: translate(-50%, 0%);
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: white;
}



@media (max-width:991px) {
    .userTagsContainer {
        display: inline-block;
        text-align: center;
    }
}


.following_followers_count {
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.following_followers_text {
    color: #929292;
    font-weight: lighter;
    font-size: 20px;
}

.gap_row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
}

.count_follow_style {
    border-left: 2px solid #353742;
}

.tabs_style {
    border-bottom: 1px solid #313131;
}

.tabs_items {
    color: #929292 !important;
    font-size: 14px !important;
    padding-top: 10px !important;


}

.tabs_items:hover {
    color: #929292 !important;
}

.tabs_items:focus {
    color: #929292 !important;
}

.active_tab {
    color: white !important;
    border-bottom: 4px solid #F68B28 !important;
    border-radius: 3px;
}

.premium {
    background-color: #F68B28;
    border-radius: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: clamp(9px, 1vw + 2px, 22px);
    padding-block: clamp(3px, 1vw + 2px, 12px);
    font-weight: bolder;
    font-size: 14px;
}

.workout_styles {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: clamp(9px, 1vw + 2px, 22px);
    padding-block: clamp(3px, 1vw + 2px, 12px);
    gap: 10px;
}

.tag_btn {
    background-color: #F68B28;
    border-radius: 16px;
    /* padding-inline: 22px; */
    padding-inline: clamp(9px, 1vw + 2px, 22px);
    padding-block: clamp(3px, 1vw + 2px, 12px);
    font-weight: bolder;
    font-size: 14px;
}


.post_image {
    width: 100%;
}

.delete_icon {
    width: 40px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 30px;
    top: 40px;
    cursor: pointer;
}

.image_boxes {
    width: 220px;
    position: relative;
}

.lightPlayBtn {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.description {
    color: #929292;
    font-weight: lighter;
}

.edit_icon {
    background: #F68B28;
    padding-inline: 12px;
    padding-block: 8px;
    border-radius: 8px;
}

.template_container {
    z-index: 9999;
    display: flex;
    align-items: flex-end;
}

.title_badge {
    background: #F68B28;
    position: absolute;
    top: -30px;
    right: 40px;
    padding-inline: 14px;
    padding-block: 6px;
    font-size: 13px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


@media only screen and (max-width: 767px) {

    .profile_name,
    .profile_description,
    .profile_sport_type {
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .post_image {
        width: 8rem;
    }

    .delete_icon {
        z-index: 1;
        width: 20px;
        padding: 0;
        margin: 0;
        top: 20px;
        right: 5%;
        position: absolute;
        cursor: pointer;
    }

    .count_follow_style {
        border-left: none;
    }

    .image_boxes {
        position: static;
    }

    .grid_boxes {
        position: relative;
    }
}