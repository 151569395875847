.addWorkoutModalContainer {
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .description {
        color: $frenchGrey;
    }

    .label {
        color: $carbonGrey;
    }

    // .checkBoxes {
    //     // border-right: 0.5px solid #A9A9A9;
    //     width: fit-content;
    //     padding-right: 4px;
    // }
    .exerciseBtn {
        background-color: transparent !important;
        border: 2px solid $carrotOrange;
        color: $carrotOrange;
    }

    .exerciseSection {
        width: 100%;
        padding: 10px;
        margin: 25px 0px;
        background-color: $mirage;
        border-radius: 20px;
        position: relative;

        .exerciseNumber {
            width: fit-content;
            font-weight: bold;
            background-color: $carrotOrange;
            padding: 6px 4px;
            border-radius: 6px 6px 0px 0px;
            position: absolute;
            right: 25px;
            top: -35px;
            z-index: -1;
        }

        .removeContainer {
            width: fit-content;
            font-weight: bold;
            background-color: $orangeyRed;
            padding: 6px 8px;
            border-radius: 6px 6px 0px 0px;
            position: absolute;
            right: 120px;
            top: -35px;
            z-index: -1;
            cursor: pointer;
        }
    }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}