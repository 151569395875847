@import "../../../global";

.Navbar {
    padding: 0 15px;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: $rangoonGreen;

    // border-bottom: 1px solid lightgray;

    @media (max-width: 768px) {
        // padding: 12px 10px;
        // height: 30px;
    }

    .titleContainer {
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        span.toggleButton {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0 10px 0 0;
            font-size: 20px;

            // @media (min-width:991.68px) {
            //     display: none;
            // }

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        h3.title {
            // font-size: 22px;
            font-weight: 700;
            margin: 0;
            font-size: clamp(1.143rem, 1vw + 1rem, 1.429rem);

            // @media (max-width: 768px) {
            //     font-size: 18px;
            // }
       
        }
    }

    .date {
        margin-right: auto;
        display: flex;
        font-size: 1.4rem;
        color: gray;
        justify-content: center;
        align-items: center;

        p {
            margin: 0 20px;
            font-size: 1rem;
        }
    }

    .search {
        display: flex;
        flex-direction: row;
        width: 20rem;
        height: auto;
        min-height: 30px;
        padding: 5px 10px;
        background-color: $darkJungleGreen;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        input {
            width: 100%;
            height: 100%;
            background-color: transparent;
            padding: 0 10px;
            color: gray;
            font-size: 14px;
            border: none;
            outline: none;
        }

        .searchButton {
            width: 35px;
            height: 30px;
            border-radius: 100%;

            // background-image: linear-gradient(to right, #B52929, #ED1B1B);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
            font-size: 1.3rem;
        }
    }

    .mobileMenu {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        font-size: 1.3rem;
        background-color: $darkJungleGreen;
        margin: 5px 5px;
        display: none;

        @media (max-width: 991px) {
            display: flex;
        }
    }

    .commentButton, .notificationButton {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        font-size: 1.3rem;
        background-color: $darkJungleGreen;
        margin: 5px 5px;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .profile {
        color: white;

        @media (max-width: 991px) {
            display: none !important;
        }
    }

    .image {
        width: 34px;
        height: 34px;
        border-radius: 100%;

        // border: 4px solid #D43737;
   
    }

    .popover {
        display: none;
        position: absolute;
        right: 10px;
        top: 50px;
        background: $rangoonGreen;
        border: 1px solid $darkJungleGreen;
        border-radius: 16px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
        z-index: 1000;
    }

    .popover.show {
        display: flex;
        width: fit-content;
        height: fit-content;

        .commentButton, .notificationButton, .profile {
            display: flex !important;
        }
    }
}
