.timePicker {
    width: 99%;
}

/* all elements inside .timePicker */
.timePicker *{
    color: white !important;
}

.timePicker div, .css-ylkkmf div{
    border: none !important;
    color: white;
}

.timePicker > div, .css-ylkkmf > div{
    border: none !important;
}

/* background color */
.css-egioen-MuiFormControl-root-MuiTextField-root{
    /* background-color: #26262E !important; */
    border: none !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
/* hide label */
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root, .css-1ald77x{
    display: none !important;
}

/* focus shadow */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: none !important;
    border-width: 0px !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #F68B281a;
    border-radius: 16px;
}

.css-gi59xn-MuiMultiSectionDigitalClock-root > ul{
background-color: white !important;
}

/* for hide outline on time field after deploy and this class .css-ylkkmf */
.css-igs3ac {
    border-width: 0px !important;
}

.css-ylkkmf{
    border: 0px !important;
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
    border-color: none !important;
    border-width:0px !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    border-radius: 16px;
} */



/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    color: white !important;
} */

/* .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
    color: white !important;
} */

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    color: white !important;
    padding: 14.5px 14px !important;
} */

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
    color: white !important; 
    opacity: 1 !important;
} */

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important; 
} */

/* .css-egioen-MuiFormControl-root-MuiTextField-root{
    border-radius: 16px !important;
} */


