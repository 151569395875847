.Payment {
    color: white;
    width: 100%;

    .selectedCard {
        padding: 10px 20px;
        border-radius: 16px;
        background-color: $balticSea;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-width: 300px;

        .cardDetails {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: center;

            p {
                margin: 0px;
                font-size: 20px;
            }

            img {
                width: 50px;
                margin: 12px;
            }

        }

        .deleteSectionContainer {
            padding: 16px 20px;
            border-left: 1px solid $darkGrey;

            .spinner {
                font-size: 15px;
                width: 1.7rem;
                height: 1.7rem;
            }

            .deleteSection {
                color: $coralRed;
                font-size: clamp(1.563rem, 1.339rem + 0.595vw, 1.875rem);
            }
        }
    }

    .addNewCard {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .buttonDiv {
            width: 150px;
        }
    }

    .PaymentCard {
        padding: 20px;
        border-radius: 16px;
        background-color: $balticSea;
    }

    .cardForm {
        padding: 20px;
        border-radius: 16px;
        background-color: $balticSea;
        min-width: 300px;

        input {
            background-color: $darkJungleGreen;
            border-radius: 8px !important;
        }
    }

}