.custom-scrollbar {
    overflow: auto;
    height: 100%;

    @media only screen and (max-width: 425px) {
        // overflow: hidden;
    }

    &::-webkit-scrollbar {
        width: 14px !important;
        display: flex;

        @media only screen and (max-width: 425px) {
            width: 0px !important;
        }
    }

    &::-webkit-scrollbar:hover {
        width: 3px !important;

        @media only screen and (max-width: 425px) {
            width: 0px !important;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #2a2c33 !important;
        border-radius: 18px !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #1f2025 !important;
        border-radius: 17px !important;
        border: 2px solid #2a2c33 !important;
        height: 40px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        /* Handle color on hover */
    }
}