.imageWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 30px;

    .image,
    div>div>iframe {
        width: 100%;
        height: 360px;
        object-fit: cover;

        // border-radius: 15%;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -o-border-radius: 30px;
    }


    .gradientOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 360px;
        background: linear-gradient(to bottom, rgba(20, 17, 17, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        border-radius: 30px;
    }
}