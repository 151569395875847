.timerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    // align-items: center;
    margin-bottom: 30px;

    .separatorContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: auto;
        margin: 0 0 10px 0px;

        .separator {
            display: inline-block;

            // width: 6px;
            // height: 6px;
            // background-color: #404549;
            // border-radius: 6px;
            margin: 0px 10px;
            font-size: 40px;
        }
    }
}

.main-digit-container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // margin: 0 5px;

    &:first-child {
        margin-left: 0;
    }

    .digit-container {
        display: flex;
        flex-direction: row;
        padding: 0;

        .single-digit {
            position: relative;
            display: flex;
            flex: 0 1 25%;
            font-size: 30px;
            background-color: #131313;
            border-radius: 15px;
            padding: 10px 12px;
            margin: 0 4px;
            color: white;

            &:first-child {
                margin-right: 2px;
            }

            // &::after {
            //     position: absolute;
            //     left: 0px;
            //     right: 0px;
            //     top: 50%;
            //     bottom: 50%;
            //     content: "";
            //     width: 100%;
            //     height: 2px;
            //     background-color: #232323;
            //     opacity: 0.4;
            // }
       
        }
    }

    .title {
        font-size: 12px;
        margin-bottom: 5px;
        color: $carrotOrange;
        font-weight: bold;
        margin-top: 5px;
        text-align: center;
    }
}
