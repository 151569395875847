.Progress {
    color: white;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;

    .first-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $balticSea;
        width: 100%;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;

        @media only screen and (max-width: 768px) {
            justify-content: center;
        }

        .left-side {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            img {
                width: 90px;
                height: 90px;
                margin-right: 16px;
                border-radius: 50px;
                object-fit: cover;
            }

            .user-details {
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    margin: 0px;
                    color: $carrotOrange;
                    font-size: small;
                }
            }
        }

        .right-side {
            padding: 10px;
        }
    }

    .second-row {
        background-color: $balticSea;
        width: 100%;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;

        .left-side {
            font-weight: bold;
            font-size: x-large;
        }

        .right-side {

            .left-arrow,
            .right-arrow {
                color: white;
                font-size: x-large;
            }

            .center-text {
                color: $carrotOrange;
                padding: 0px 6px;
                font-weight: bold;
                width: 100px;
                text-align: center;
            }
        }

        .viewChart {
            background-color: transparent;
            border: none;
            color: $carrotOrange;
            white-space: nowrap;
            text-decoration: underline;

            &:focus-within,
            &:focus-visible {
                outline: none;
            }
        }

        .weekly-view {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $darkJungleGreen;
            padding: 20px;
            border-radius: 20px;
            margin-top: 14px;

            :first-child {
                font-weight: bold;
                color: $white;
            }

            :last-child {
                font-weight: bold;
                color: $carrotOrange;
            }
        }

        .monthly-view {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 0px;
            text-align: center;

            .day-names {
                display: contents;

                .day-name {
                    font-weight: bold;
                    padding: 10px 0;
                }
            }

            .days {
                display: contents;

                .day {
                    padding: 30px 10px;
                    border: 0.5px solid #313131;

                    @media only screen and (max-width: 425px) {
                        padding: 20px 10px;
                    }
                }

                .current-month {
                    // background-color: white;
                    font-weight: bold;

                    &.active-day {
                        color: $carrotOrange ;
                    }
                }



                .other-month {
                    // background-color: #d3d3d3;
                    color: #7d7d7d;
                }
            }
        }

        .yearly-view {
            width: 100%;
            height: fit-content;
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
            grid-auto-flow: row dense;
            justify-content: space-between;
            align-content: space-between;
            justify-items: center;
            align-items: center;
            margin-block: 10px;

            .month {
                background-color: $balticSea;
                border-radius: 18px;
                padding-block: 30px;
                padding-inline: clamp(4px, 1vw + 1px, 30px);
                width: 100%;
                text-align: center;
                box-shadow: 0px 4px 12px 0px #0000001F;
                border: 1px solid $gunmetal;

                :first-child {
                    color: $carrotOrange;
                    font-weight: bold;
                    font-size: clamp(16px, 1.052rem + 0.359vw, 22px);
                }

                :last-child {
                    font-size: small;
                }
            }
        }
    }

    // MARK: SELECT
    .select {
        background-color: $carrotOrange;
        border: 0px solid #48556F !important;
        border-radius: 18px !important;
        color: white !important;
        font-size: 16px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        line-height: 1.4 !important;
        padding: 30px;
        margin: 6px 0px 2px 0px;
        width: 100%;
        height: 50px;

        // arrow working
        background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
        background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0;
        background-size: 6px 10px, 6px 10px;
        background-repeat: no-repeat;

        // hide default arrow
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    /* .select::placeholder {
        color: #8F8F8F !important;
    } */

    .select:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    }

    .select:required:invalid {
        color: #8F8F8F !important;
    }

    option {
        color: white;
    }

    //  hide arrow of select field
    .select {
        -webkit-appearance: none;
        -moz-appearance: none;

        /* text-indent: 1px; */
        /* text-overflow: ''; */
    }
}