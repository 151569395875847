.AccountLayout {
    width: 100%;
    height: 100dvh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    // padding: 5% 8%;
    position: relative;

    @media (orientation: landscape) and (max-width: 991px) {
        // padding: 1% 2%;
    }

    @media (orientation: portrait) and (max-width: 991px) {
        // padding: 14% 2%;
    }

    .sectionContainer {
        // height: 100%;
        height: 100vh;
        width: 100%;

        // max-width: 1100px;
        // border-radius: 22px;
        overflow: hidden;
        position: relative;

        .backButtonContainer {
            position: absolute;
            top: 20px;
            left: 20px;
            border: 1px solid #48556F;
            padding: 6px;
            font-size: 1.3rem;
            border-radius: 100%;
            display: inline-flex;
            color: white;
            cursor: pointer;

            @media (max-width: 991px) and (orientation: landscape) {
                position: relative;
                top: -5px;
                left: -5px;
                margin-right: auto;
                margin-bottom: 10px;
            }
        }

        .row {
            height: 100%;

            .formContainer,
            .mediaContainer {
                height: 100%;

                // padding: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                @media (max-width: 991px) {
                    // padding: 15px;
                }
            }

            .formContainer {
                // background-color: #181829;
                overflow: hidden;

                .form {
                    height: fit-content;
                    width: 100%;

                    // max-width: 420px;
                    padding: 30px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    // justify-content: center;
                    color: white;
                }

                .stickyImage {
                    position: fixed !important;
                    bottom: 0px !important;
                    right: -55% !important;
                    z-index: -1 !important;

                    @media (max-width: 991px) {
                        width: 100% !important;
                        right: 3% !important;
                    }

                    @media (min-width: 1432px) {
                        width: 100% !important;
                        right: -55% !important;
                    }
                }
            }

            .mediaContainer {
                // background-color: #0D0D1C;

                @media (max-width: 991px) {
                    display: none;
                }

                .image {
                    width: 100%;
                    height: 100%;

                    // padding-right: 20px;

                    &.logo {
                        width: 70%;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
