.Inbox {
    width: 100%;
    height: 90%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #202127;
    border-radius: 18px;

    .titleContainer {
        width: 100%;
        padding: 15px;
        border-radius: 15px 15px 0px 0px;
        background-color: #282831;



        .userContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .leftSide {
                display: flex;
                position: relative;
                align-items: center;

                .backButton {
                    border: 1px solid #48556F;
                    padding: 6px;
                    font-size: 1.3rem;
                    border-radius: 100%;
                    display: inline-flex;
                    color: white;
                    cursor: pointer;
                    margin-right: 10px;
                }

                .imageDiv {
                    position: relative;

                    .image {
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        object-fit: cover;
                    }

                    .onlineIndicator {
                        padding: 5px;
                        border-radius: 100%;
                        border: 2px solid white;
                        background: #01C7A3;
                        position: absolute;
                        bottom: 3px;
                        right: 3px;
                        z-index: 1;
                    }
                }

                .nameContainer {
                    width: 100%;
                    padding-left: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .onlineText {
                        margin: 0;
                        font-size: 0.78rem;
                        color: #616161;
                        opacity: .8;
                    }
                }
            }

            .rightSide {

                .popover {
                    display: none;
                    position: absolute;
                    right: 10px;
                    top: 50px;
                    background: $rangoonGreen;
                    border: 1px solid $darkJungleGreen;
                    border-radius: 10px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
                    z-index: 1000;

                    p {
                        padding: 10px;
                        color: white;

                        &:hover {
                            background-color: $darkJungleGreen;
                            border-radius: 8px;
                        }
                    }

                    .show {
                        display: flex;
                        width: -moz-fit-content;
                        width: fit-content;
                        height: -moz-fit-content;
                        height: fit-content;
                    }
                }
            }


        }
    }

    .chatContainer {
        width: 100%;
        height: 100%;
        padding: 15px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        position: relative;

        .documentContainer {
            width: 220px;
            height: 220px;
            position: fixed;
            left: 10px;
            bottom: 65px;
            background-color: #282831;
            border-radius: 20px;
            color: white;
            z-index: 1;

            .closeIcon {
                position: absolute;
                right: 10px;
                top: 8px;
                cursor: pointer;
                z-index: 1;
            }

            .contentContainer {
                width: 100%;
                height: 100%;
                padding: 0 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .documentIcon {
                    font-size: 4rem;
                }

                .fileName {
                    width: 100%;
                    overflow: hidden;
                    word-break: break-word;
                    text-align: center;
                }

                .image {
                    width: 90%;
                    height: 90%;
                }

                .video {
                    width: 90%;
                    height: 90%;
                }
            }
        }

        .badgeMessage {
            margin: 8px auto;
            padding: 10px 20px;
            align-items: center;
            display: inline-flex;
            border-radius: 20px;
            background-color: #282831;
            color: white;
            font-size: 12px;
        }

        .chat {
            // width: fit-content;
            max-width: 50%;
            margin: 8px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;



            &.me {
                margin-left: auto;
                align-items: flex-end;

            }

            .info {
                // width: fit-content;
                color: white;
                opacity: .4;
                font-size: .7rem;
            }

            .messageFile {
                width: 100%;
                height: 100%;
                max-width: 400px;
                max-height: 400px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &.me {
                    .file {
                        margin-left: auto;

                    }
                }

                .file {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;
                    padding: 10px;
                    background-color: #282831;
                    color: white;
                    margin-right: auto;

                    .documentIcon {
                        font-size: 4rem;
                    }

                    .fileName {
                        width: 100%;
                        overflow: hidden;
                        word-break: break-word;
                        text-align: center;
                        text-decoration: none;
                        color: white;
                    }
                }




                .image {
                    width: 90%;
                    height: 90%;
                }

                .video {
                    width: 90%;
                    height: 90%;
                }
            }

            .message {
                padding: 10px 15px;
                border-radius: 100%;
                margin: 0;
                background: #26262E;
                border-radius: 0px 20px 20px 20px;
                color: white;
                font-size: .85rem;

                &.me {
                    border-radius: 20px 0 20px 20px;
                    background-color: $carrotOrange
                }
            }
        }
    }

    .messageContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px;

        .messageBar {
            background-color: white !important;
            color: black !important;
        }

        .input-before-icon {
            color: #9E9E9E !important;
            top: 10px !important;
        }

        .input-after-icon {
            color: #9E9E9E !important;
            top: 10px !important;
            cursor: pointer;
        }


        .sendButton {
            padding: 8px 14px;
            border-radius: 100%;
            border: none;
            font-size: 1.5rem;
            margin-left: 10px;
            background-color: $carrotOrange;

            &:focus-within,
            &:focus-visible {
                outline: none;
            }
        }
    }
}