.ChatThreads {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .searchButton {
            padding: 8px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: white;
            background: #0D0D1C;
            border-radius: 8px;
        }
    }

    .threads {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;


        .search {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: auto;
            height: 40px;
            padding: 5px 10px;
            background-color: $darkJungleGreen;
            border-radius: 10px;
            justify-content: center;
            align-items: center;

            input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                padding: 0 10px;
                color: gray;
                font-size: 14px;
                border: none;
                outline: none;
            }

            .searchButton {
                width: 35px;
                height: 30px;
                border-radius: 100%;

                // background-image: linear-gradient(to right, #B52929, #ED1B1B);
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                cursor: pointer;
                font-size: 1.3rem;
            }
        }


        .thread-card {
            width: 100%;
            position: relative;
            border-radius: 10px;
            background: $balticSea;
            overflow: hidden;
            padding: 1.05px;

            .thread {
                width: -webkit-fill-available;
                height: fit-content;
                padding: 5px;
                margin: 10px;
                border-radius: 18px;
                display: flex;
                flex-direction: row;



                .imageDiv {
                    position: relative;

                    .image {
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        object-fit: cover;
                    }

                    .onlineIndicator {
                        padding: 7px;
                        border-radius: 100%;
                        // border: 2px solid white;
                        background: #19DF2D;
                        position: absolute;
                        bottom: 3px;
                        right: 3px;
                        z-index: 1;
                    }
                }

                .userInfo {
                    width: 100%;
                    padding-left: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;

                    .nameContainer {
                        display: flex;
                        flex-direction: column;
                        align-items: start;

                    }

                    .indicator {
                        height: fit-content;
                        margin-left: 8px;
                        padding: 6px;
                        background: #3086F3;
                        border-radius: 100%;
                    }

                    .message {
                        margin: 0;
                        font-size: 0.78rem;
                        color: #616161;
                        opacity: .8;
                    }
                }
            }

            .last-time {
                margin: 0px;
                position: absolute;
                bottom: 8px;
                right: 18px;
                color: #616161;
            }
        }

        .group-thread-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .group-thread-card {
                width: 100%;
                max-width: 300px;
                position: relative;
                border-radius: 10px;
                background: $balticSea;
                overflow: hidden;
                padding: 1.05px;
                margin: auto;



                .group-thread {
                    width: -webkit-fill-available;
                    height: fit-content;
                    padding: 5px;
                    margin: 10px;
                    border-radius: 18px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .imageDiv {
                        position: relative;
                        align-items: center;
                        display: flex;
                        flex-direction: column;

                        .image {
                            width: 150px;
                            height: 150px;
                            border-radius: 100%;
                            object-fit: cover;
                        }
                    }

                    .usersInfo {
                        width: 200px;
                        display: flex;

                        .existingLogo {
                            color: white;
                            background-color: $darkJungleGreen;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;
                            font-size: large;
                        }
                    }
                }
            }
        }
    }

}