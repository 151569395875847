.freeTemplateViewModalContainer {
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .templateImage {
        width: 100%;
        max-height: 250px;
        aspect-ratio: 16/9;

        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }

    .content-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;

        .section-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .exercise-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                &::before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: white;
                    border: 1px solid #F68B28;
                    background-color: #F68B28;
                    border-radius: 50%;
                    position: absolute;
                    left: -18px;
                    top: 12px;
                }

                &::after {
                    content: '';
                    height: 100%;
                    padding: 2px;
                    border: none;
                    border-left: 2px dashed #F68B28;
                    position: absolute;
                    left: -13px;
                    top: 16px;
                }

                .extra-after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    // background: white;
                    border: 3px solid #F68B28;
                    background-color: white;
                    border-radius: 50%;
                    position: absolute;
                    left: -18px;
                    // bottom: 12px;
                    top: 105%;
                    z-index: 1;
                }

                .badge {
                    height: fit-content;
                    padding: 8px 12px;
                    border-radius: 30px;
                }
            }
        }
    }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}