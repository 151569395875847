.PostView {
    width: 100%;

    // height: fit-content;
    overflow-y: auto;

    .headerContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .titleContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-right: auto;
        padding-right: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .modalContainer {
        /* padding: 20px; */
        width: 100vw;
        height: 100dvh;

        /* max-width: 500px; */
        /* height: fit-content; */
        /* max-height: 500px; */
        /* max-height: 80vh; */
        border-radius: 18px;
        background-color: #0D0D1C;
        color: white;
        display: flex;
        flex-direction: column;

        /* align-items: center; */
        position: relative;
        z-index: 1000;
        overflow: scroll;
    }

    .authorTag{
        background-color: $orangeyRed;
        padding: 4px 14px;
        color: white;
        font-weight: bold;
        border-radius: 16px;
    }

    @media only screen and (max-width: 768px) {
        .modalContainer {
            max-width: 90vw;
        }
    }
}

@media (max-width: 991px) {}
