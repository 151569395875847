.freeTag {
    background-color: $carrotOrange;
    color: white;
    border-radius: 8px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px 14px;
    font-weight: bold;
}
