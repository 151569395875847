.Accolades {
    color: white;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;

    .first-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $balticSea;
        width: 100%;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;

        @media only screen and (max-width: 768px) {
            justify-content: center;
        }

        .left-side {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            img {
                width: 90px;
                height: 90px;
                margin-right: 16px;
                border-radius: 50px;
                object-fit: cover;
            }

            .user-details {
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    margin: 0px;
                    color: $carrotOrange;
                    font-size: small;
                }
            }
        }

        .right-side {
            padding: 10px;
        }
    }

    .second-row {
        background-color: $balticSea;
        width: 100%;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        min-width: 300px;

        .badges-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 35px;

            .badge {
                width: 100px;

                p {
                    text-wrap: wrap;
                    font-size: small;
                }
            }
        }
    }
}
