.imageWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 30px;

    .image,
    div>div>iframe {
        width: 100%;
        height: 360px;
        object-fit: cover;

        // border-radius: 15%;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .thumbnail-container {
        width: 100%;
        height: 100%;
        position: relative;

        span {
            position: absolute;
            width: 120px;
            height: 120px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;

            .play-icon {
                width: 100%;
                height: 100%;
            }
        }
    }


    .gradientOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 360px;
        background: linear-gradient(to bottom, rgba(20, 17, 17, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        border-radius: 30px;
    }
}