.premium {
    width: 46px;
    height: 46px;
    background-color: $carrotOrange;
    border-radius: 8px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .image {
        margin: auto;
    }
}