.ContactUs {
    color: white;
    width: 100%;

    .contact-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
       
        background-color: $balticSea;
        width: 100%;
        height: fit-content;
        margin-top: 12px;
        padding: 24px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;

        @media only screen and (max-width: 768px) {
            justify-content: center;
        }

        .left-side {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            img {
                width: 100px;
                margin-right: 6px;
            }
            .contact-details{
                display: flex;
                flex-direction: column;
                justify-content: center;
                p{
                    margin: 0px;
                    font-size: medium;
                }
            }
        }
        .right-side{
            padding:10px;
        }

    }
}