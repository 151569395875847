.grid_post {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (min-width: 600px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
        display: block;
        object-fit: cover;
    }
}