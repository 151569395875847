.addWorkoutTemplateModalContainer {
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .templateName {
        width: 90%;

        .templateNameField {
            width: fit-content;
            background-color: transparent;
            border: none;
            color: white;
            font-size: larger;
            font-weight: bold;

            &:focus-visible {
                outline-offset: 0px;
                border: none;
                border-color: transparent;
                padding: 1px;
                outline: none;
            }
        }
    }

    .sectionArea {
        background-color: $balticSea;
        width: 100%;
        border-radius: 18px;
        padding: 14px;
        margin-top: 8px;
    }

    .sectionNameField {
        width: 90%;
        width: fit-content;
        background-color: transparent;
        border: none;
        color: white;
        font-weight: bold;

        &:focus-visible {
            outline-offset: 0px;
            border: none;
            border-color: transparent;
            padding: 1px;
            outline: none;
        }
    }

    // MARK: SELECT
    .select {
        background-color: $darkJungleGreen;
        border: 0px solid #48556F !important;
        border-radius: 18px !important;
        color: #8F8F8F !important;
        font-size: 16px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        line-height: 1.4 !important;
        padding: 15px;
        margin: 6px 0px 2px 0px;
        width: 100%;
        height: 50px;

        // arrow working
        background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
        background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0;
        background-size: 6px 10px, 6px 10px;
        background-repeat: no-repeat;

        // hide default arrow
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    /* .select::placeholder {
        color: #8F8F8F !important;
    } */

    .select:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    }

    .select:required:invalid {
        color: #8F8F8F !important;
    }

    option {
        color: white;
    }

    //  hide arrow of select field
    .select {
        -webkit-appearance: none;
        -moz-appearance: none;

        /* text-indent: 1px; */
        /* text-overflow: ''; */
    }

    // hide time indicator
    input[type="time"]::-webkit-calendar-picker-indicator {
        display: none;
    }

    .description {
        color: $frenchGrey;
    }

    .label {
        color: $carbonGrey;
    }

    // .checkBoxes {
    //     // border-right: 0.5px solid #A9A9A9;
    //     width: fit-content;
    //     padding-right: 4px;
    // }

    .exerciseBtn {
        background-color: transparent !important;
        border: 2px solid $carrotOrange;
        color: $carrotOrange;
    }

    .exerciseSection {
        width: 100%;
        padding: 10px;
        margin: 25px 0px;
        background-color: $mirage;
        border-radius: 20px;
        position: relative;

        .exerciseNumber {
            width: fit-content;
            font-weight: bold;
            background-color: $carrotOrange;
            padding: 6px 4px;
            border-radius: 6px 6px 0px 0px;
            position: absolute;
            right: 25px;
            top: -35px;
            z-index: -1;
        }
    }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

.css-ylkkmf {
    border: 0px solid;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}

fieldset {
    border: none !important;
}