.Subscription {
    color: white;
    width: 100%;

    .subscription-row {
        width: 100%;
        background-color: $balticSea;
        height: fit-content;
        margin-top: 12px;
        padding: 30px;
        border-radius: 14px;
        align-items: center;
        min-width: 300px;
    }

    .purchase-container {
        display: flex;
        flex-direction: column;
        align-items: start;

        @media only screen and (max-width : 768px) {
            align-items: center;
        }

        .purchase-btn {
            color: white !important;
            border: 1px solid white;
            border-radius: 20px;
            background-color: transparent !important;
        }
    }



.subscription-board {
    background-color: $gunmetal;
    max-width: 300px;
    height: 150px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;

    .one-time {
        background-color: $carrotOrange;
        border-radius: 25px;
        padding: 4px 10px;
        height: fit-content;
        position: absolute;
        top: -10px;

        p {
            margin: 0px;
        }
    }

    .center-box {

        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 auto;

        .month {
            color: white;
            margin: 0 auto;

        }

        .amount {
            color: white;
            margin: 0 auto;
            font-size: 50px;
            font-weight: bold;
        }
    }
}

.trial-btn {
    width: 300px;
    color: #121212 !important;
    // border: 1px solid white;
    border-radius: 20px;
    background-color: white !important;
}
}