.ReportPostModal {
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;
    background-color: $darkJungleGreen;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;

    @media only screen and (max-width: 510px) {
        max-width: 300px;
    }

    .closeBtn {
        cursor: pointer;
        background-color: white;
        border-radius: 100px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .post-image {
        width: 100%;
        // overflow: hidden;
        border-radius: 30px;

        img {
            width: 100%;
            height: 220px;
            object-fit: cover;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px;
        }
    }

    // .description {
    //     color: $frenchGrey;
    // }

    // .label {
    //     color: $carbonGrey;
    // }

    // .checkBoxes {
    //     // border-right: 0.5px solid #A9A9A9;
    //     width: fit-content;
    //     padding-right: 4px;
    // }
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}