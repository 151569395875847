.downloadTag {
    background-color: $carrotOrange;
    color: white;
    border-radius: 8px;
    position: fixed;
    top: -10px;
    right: 100px;
    padding: 8px 14px;
    font-weight: bold;
    z-index: 5;
}
