.imageWrapper {
    position: relative;
    aspect-ratio: 4/4;
    max-height: 300px;
    margin: auto;
    overflow-y: hidden;
    border-radius: 30px;
    user-select: none;

    .image {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -o-border-radius: 30px;
        user-select: none;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}