.chart-container-custom {
    position: relative;
}

.chart-container-custom .theme-chart .wrapper {
    max-height: 550px;
    aspect-ratio: 16/9;
    margin: 0 auto;
}

.chart-container-custom .theme-chart .wrapper canvas {
    width: 100% !important;
    height: 100% !important;
}

.chart-container-custom .month-dropdown {
    position: absolute;
    top: 60px;
    right: 45px;
    z-index: 1;
    display: flex;
    background: transparent;
    border: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 95.52px;
    height: 26.1px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.chart-container-custom .month-dropdown :active {
    border: hidden;
}

.chart-container-custom .month-dropdown :hover {
    border: hidden;
}