.Layout {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    background-color: $rangoonGreen;
    padding: 12px;
    
    .row {
        height: -webkit-fill-available;
        overflow: hidden;

        .col {
            height: 100%;
            overflow: auto;
        }
    }
}