.select{
    background-color: #26262E !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    height: 50px;
    /*  arrow working */
    background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
    background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0;
    background-size: 6px 10px, 6px 10px;
    background-repeat: no-repeat;

    /*  hide default arrow */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
/* .select::placeholder {
    color: #8F8F8F !important;
} */

.select:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(246, 139, 40, 0.1);
}

.select:required:invalid { color: #8F8F8F !important; }

option {
    color: white;
}